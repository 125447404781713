.sb-events-import---container {








	

	> h6 {
		display: block;
		text-align: center;
		opacity: 0.25;
	}
	> section {
		padding: var(--padding---global---page);
		> * {
			margin-bottom: 20pt;
		}
	}
}










.sb-events-import---poster-card {
	margin: 0;
	img {
		width: 100%;
		aspect-ratio: var(--aspect-ratio--event-poster);
	}
}




