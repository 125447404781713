.sb-login---container {
	display: flex;
	justify-content: center;
	width: 100vw;
	height: 100vh;
	padding: var(--padding---global) var(--padding---global) 50pt var(--padding---global);
	> div {
		display: flex;
		flex-direction: column;
		justify-content: center;
		height: 100%;
		width: 300pt;
		> img {
			display: block;
			width: 100%;
			height: 150pt;
			transform: scale(0.75);
			object-fit: contain;
		}
		> input {
			width: 100%;
			margin-bottom: var(--padding---global);
			text-align: center;
		}
		> h6 {
			text-align: center;
			color: var(--color---primary);
			font-size: 10pt;
		}
		> p {
			display: flex;
			padding: 0 10pt;
			> label {
				cursor: pointer;
				> input {
					display: none;
				}
				> i {
					display: block;
					font-size: 14pt;
					line-height: 16pt;
					&.fa-check-square {
						color: var(--color---primary);
					}
					&.fa-square {
						color: var(--color---danger);
					}
				}
			}
			> span {
				display: block;
				width: 100%;
				margin: 0;
				font-size: 10pt;
				line-height: 16pt;
				text-align: justify;
				padding-left: 15pt;
				> span {
					font-weight: bold;
					color: var(--color---folly);
					&:hover { text-decoration: underline !important; }
					cursor: pointer;
				}
			}
		}
		> div {
			display: flex;
			justify-content: center;
			width: 100%;
			height: 30pt;
			padding: 0;
			gap: 10pt;
			> button {
				display: flex;
				width: auto;
				height: 35pt;
				cursor: pointer;
				border-radius: var(--border-radius---button);
				background-color: var(--color---folly);
				text-overflow: ellipsis;
				overflow: hidden;
				padding: var(--padding---global) calc(var(--padding---global) * 2);
				text-align: center;
				gap: 10pt;
				> i {
					display: block;
					height: 15pt;
					line-height: 15pt;
					width: auto;
				}
				> span {
					display: block;
					height: 15pt;
					line-height: 15pt;
					text-transform: uppercase;
					letter-spacing: 1;
					font-weight: bold;
					width: auto;
					overflow: hidden;
					text-overflow: ellipsis;
					overflow: hidden;
					
				}
			}
		}
	}
}

.sb-login---copyright {
	position: fixed;
	right: 0;
	bottom: 0;
	left: 0;
	display: block;
}