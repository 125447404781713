.md-ticket-edit-type---container {
	display: flex;
	flex-direction: column;
	justify-content: center;
	height: 100%;
	width: 100%;
	gap: 10pt;
	padding: var(--padding---global---modal);
	> .md-ticket-edit-type---title {
		display: block;
		width: 100%;
		text-align: center;
		margin-bottom: 10pt;
	}
}