.sb-payments-users---pr-list {
	ion-note {
		text-align: center;
		width: 14vw;
		b {
			color: var(--color---greeny);
			&:last-child {
				color: unset !important;
			}
		}
	}
}