.sb-event-view---container {
	padding-bottom: 20pt;
	> .sb-event-view---image {
		display: block;
		width: 100%;
		margin: 0;
		min-height: 120pt;
		height: auto;
		overflow: hidden;
		> img {
			display: block;
			width: 100%;
			object-fit: contain;
			background-color: transparent;
			border-radius: var(--border-radius---global);
			background-color: var(--color---shadow);
			margin: 0;
		}
	}
	> h5 {
		display: flex;
		justify-content: space-between;
		color: var(--color---dark);
		> a {
			font-weight: bold;
			color: var(--color---folly);
		}
		> span {
			font-weight: bold;
			opacity: 0.5;
		}
	}
	> h3 {
		padding: 10pt 0 !important;
	}
	.sb-poi-view---map {
		padding: 10pt 0 !important;
	}
	> .sb-event-view---ticket-types {
		> h5 {
			text-align: center;
		}
		> section {
			padding-top: 15pt;
			display: flex;
			flex-direction: column;
			justify-content: space-between;
			gap: 15pt;
			height: auto;
			width: 100%;
		}




	}


















}