.md-sign-password-change---container {
	display: block;
	padding: var(--padding---global---page);
	width: 100%;
	> img {
		display: block;
		transform: scale(0.5);
	}
	> p {
		text-align: center;
	}
}