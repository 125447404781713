.sb-payments-users-overview---container {
	padding: var(--padding---global---modal);
	> .sb-payments-users-overview---user-image {
		display: flex;
		justify-content: center;
		> img {
			display: block;
			width: 30vw;
			height: 30vw;
			object-fit: cover;
			border-radius: var(--border-radius---card);
		}
	}
	> h3 {
		display: block;
		width: 100%;
		text-align: center;
		text-transform: uppercase;
		line-height: 1.5;
		font-weight: bold;
		> span {
			text-transform: lowercase;
			opacity: 0.5;
		}
	}
	> h5 {
		display: block;
		width: 100%;
		text-align: center;
	}
	> .sb-payments-users-overview---pay-table {
		display: block;
		width: 100%;
		ion-list {
			ion-item {
				ion-note {
					width: 100vw;
				}
			}
		}
	}

	/*

	


	<IonNote>
												<span>
													<span>{ ___upp['ticket_count_unpayed'] }</span>
													<i className="fas fa-ticket"></i>
												</span>
												<span> × </span>
												<span>{ ___upp['ticket_payment_rate'] } €</span>
											</IonNote>
										</IonItem>





*/









}
