//#region 																							I M P O R T S

	@import url('https://cdnjs.cloudflare.com/ajax/libs/font-awesome/6.5.2/css/all.min.css');
	@import url('https://fonts.googleapis.com/css2?family=Quicksand:wght@300..700&display=swap');

//#endregion

//#region 																							D E B U G

						//					* 			{ outline: 1px solid orange !important; }

//#endregion

//#region 																							O V E R R I D E S

	* {
		box-sizing: border-box !important;
	}

	*:not(i) {
		font-family: 'Quicksand', sans-serif !important;
		font-style: normal;
		font-optical-sizing: auto;
	}

	::-webkit-scrollbar,
	*::-webkit-scrollbar {
		-webkit-appearance: none !important;
		display: none !important;
		opacity: 0 !important;
		width: 0pt !important;
		visibility: hidden !important;
		transform: translate(-829vw) !important;
	}

	.scroll-bar-indicator {
		display: none !important;;
		opacity: 0 !important;
	}

	.no-shadow {
		box-shadow: none !important;
	}

	ion-card {
		margin: 0 !important;
		ion-list {
			p {
				padding: 0 10pt;
			}
		}
	}

	a,
	a * {
		text-transform: unset;
		text-decoration: none;
	}

	ion-content {
		--offset-bottom: auto !important;
		--overflow: hidden;
		overflow: auto;
		&::-webkit-scrollbar {
			display: none;
		}
	}

	ion-toolbar {
		--background: var(--color---light) !important;
		--border-color: var(--color---light) !important;
	}

	ion-footer {
		padding: env(safe-area-inset-bottom);
	}

//#endregion

//#region 																							I N P U T S

	input {
		height: 30pt;
		line-height: 30pt;
		border: none;
	}

	.input---nb-label {
		display: block;
		width: 100%;
		height: 10pt;
		line-height: 10pt;
		font-size: 10pt;
		font-weight: bold;
		opacity: 0.5;
		text-transform: lowercase;
		margin-bottom: 5pt;
		letter-spacing: 0.25pt;
		margin: 10pt 0;
		z-index: 1;
		&.is-required {
			&::after {
				content: 'richiesto';
				color: var(--color---folly);
				padding-left: 10pt;
			}
		}
	}

	.input---nb-outlined {
		display: block;
		width: 100%;
		font-size: 12pt;
		line-height: 18pt;
		height: auto;
		min-height: auto;
		max-height: auto;
		border-radius: 6pt;
		background-color: unset;
	//	border: 1pt solid var(--color---blacky);
		border: none !important;
		box-shadow: var(--shadow---inset) !important;
		padding: 10pt;
		resize: none;
	}

	textarea {
		resize: none !important;
	}

	.input---wrong {
		border-color: var(--color---folly) !important;
	}

	input:focus,
	input:active,
	textarea:focus,
	textarea:active,
	select:focus,
	select:active {
		outline: none !important;
	}

//#endregion

//#region 																							C O N T A I N E R

	.sb-page---container {
		position: relative;
		display: block;
		width: 100vw;
		height: auto;
		min-height: 80vh;
		padding: var(--padding---global---page);
		background-color: var(--color---light);
		&.ignore-limits {
			padding: 0 !important;
		}
	}

	.md-modal---container {
		position: relative;
		display: block;
		width: 100vw;
		height: auto;
		padding: var(--padding---global---modal);
		background-color: var(--color---light);
		&.ignore-limits {
			padding: 0 !important;
		}
		&.is-swipable {
			padding-top: 45pt !important;
		}
	}

	.container---footer-section {
		display: flex;
		gap: 10pt;
		width: 100%;
		height: 60pt;
		padding: var(--padding---global);
		* {
			font-size: 14pt;
			line-height: 40pt;
			margin: 0;
		}
	}

//#endregion

//#region 																							E L E M E N T S

	.is-ion-item-button {
		> i {
			color: var(--color-blacky);
			width: 20pt;
			text-align: center;
			margin-right: 10pt;
			opacity: 0.5;
		}
		> img {
			width: 20pt;
			margin-right: 10pt;
			object-fit: contain;
			transform: scale(0.75);
		}
	}

	.is-element {
		display: block;
		background-color: var(--color---light);
		box-shadow: var(--shadow---standard);
		&.is-card {
			border-radius: var(--border-radius---card);
			box-shadow: var(--shadow---standard);
			padding: var(--padding---global);
			overflow: hidden;
			&.no-padding {
				padding: 0 !important;
			}
		}
		&.is-button {
			display: flex;
			justify-content: center;
			gap: 10pt;
			min-width: 40pt;
			line-height: 40pt;
			background-color: var(--color---shadow);
			color: var(--color---dark);
			border-radius: var(--border-radius---button);
			font-size: 20pt;
			> i {
				display: block;
				width: auto;
				height: 40pt;
				line-height: 40pt;
				text-align: center;
			}
			> h4 {
				display: block;
				width: auto;
				height: 40pt;
				line-height: 40pt;
			}
			&.is-full-width {
				width: 100%;
			}
		}
		&.is-color---folly {
			background-color: var(--color---folly);
			color: var(--color---light);
			* { color: var(--color---light); }
		}
		&.is-color---shadow {
			background-color: var(--color---shadow);
			color: var(--color---dark);
			* { color: var(--color---dark); }
		}
		&.is-color---success {
			background-color: var(--color---greeny);
			color: var(--color---light);
			* { color: var(--color---light); }
		}
		&.is-color---warning {
			background-color: var(--color---yelly);
			color: var(--color---light);
			* { color: var(--color---light); }
		}
		&.is-color---danger {
			background-color: var(--color---reddy);
			color: var(--color---light);
			* { color: var(--color---light); }
		}
		&.is-color---transparent {
			background-color: transparent;
			color: var(--color---dark);
			* { color: var(--color---dark); }
		}
	}

//#endregion