/* Ionic Variables and Theming. For more info, please see:
http://ionicframework.com/docs/theming/ */

/**   Ionic CSS Variables   **/

:root {

	--aspect-ratio--event-poster: 9 / 16;

	--border-radius---button: 7pt;
	--border-radius---card: 7pt;
	--border-radius---global: 10pt;

	--padding---global: 10pt;
	--padding---global---modal: 10pt;
	--padding---global---page: 10pt;

	--safe-area---top: env(safe-area-inset-top);
	--safe-area---bottom: env(safe-area-inset-bottom);

	--dimension---top-bar: 50pt;

	/** colors **/

	--color---light: #FFFFFF;
	--color---shadow: #F4F4F4;
	--color---folly: #263238;
	--color---greeny: #09BC89;
	--color---yelly: #FFCB22;
	--color---reddy: #eb445a;
	--color---blacky: #7e7e7e;
	--color---dark: #0D0D0D;

	/** shadow **/

	--shadow---standard: 0pt 0pt 6pt 0.5pt rgba(0, 0, 0, 0.15);
	--shadow---inset: inset 0pt 0pt 6pt 0.5pt rgba(0, 0, 0, 0.15);

	/** primary **/

	--ion-color-primary: var(--color---folly);
	--ion-color-primary-rgb: var(--color---folly);
	--ion-color-primary-contrast: var(--color---light);
	--ion-color-primary-contrast-rgb: var(--color---folly);
	--ion-color-primary-shade: var(--color---folly);
	--ion-color-primary-tint: var(--color---folly);

	/** secondary **/

	--ion-color-secondary: #3dc2ff;
	--ion-color-secondary-rgb: 61, 194, 255;
	--ion-color-secondary-contrast: var(--color---light);
	--ion-color-secondary-contrast-rgb: var(--color---folly);
	--ion-color-secondary-shade: #36abe0;
	--ion-color-secondary-tint: #50c8ff;

	/** tertiary **/

	--ion-color-tertiary: #5260ff;
	--ion-color-tertiary-rgb: 82, 96, 255;
	--ion-color-tertiary-contrast: var(--color---light);
	--ion-color-tertiary-contrast-rgb: var(--color---folly);
	--ion-color-tertiary-shade: #4854e0;
	--ion-color-tertiary-tint: #6370ff;

	/** success **/

	--ion-color-success: #2dd36f;
	--ion-color-success-rgb: 45, 211, 111;
	--ion-color-success-contrast: var(--color---light);
	--ion-color-success-contrast-rgb: var(--color---folly);
	--ion-color-success-shade: #28ba62;
	--ion-color-success-tint: #42d77d;

	/** warning **/

	--ion-color-warning: #ffc409;
	--ion-color-warning-rgb: 255, 196, 9;
	--ion-color-warning-contrast: var(--color---dark);
	--ion-color-warning-contrast-rgb: 0, 0, 0;
	--ion-color-warning-shade: #e0ac08;
	--ion-color-warning-tint: #ffca22;

	/** danger **/

	--ion-color-danger: #eb445a;
	--ion-color-danger-rgb: 235, 68, 90;
	--ion-color-danger-contrast: var(--color---light);
	--ion-color-danger-contrast-rgb: var(--color---folly);
	--ion-color-danger-shade: #cf3c4f;
	--ion-color-danger-tint: #ed576b;

	/** dark **/

	--ion-color-dark: #222428;
	--ion-color-dark-rgb: 34, 36, 40;
	--ion-color-dark-contrast: var(--color---light);
	--ion-color-dark-contrast-rgb: var(--color---folly);
	--ion-color-dark-shade: #1e2023;
	--ion-color-dark-tint: #383a3e;

	/** medium **/

	--ion-color-medium: #92949c;
	--ion-color-medium-rgb: 146, 148, 156;
	--ion-color-medium-contrast: var(--color---light);
	--ion-color-medium-contrast-rgb: var(--color---folly);
	--ion-color-medium-shade: #808289;
	--ion-color-medium-tint: #9d9fa6;

	/** light **/

	--ion-color-light: #f4f5f8;
	--ion-color-light-rgb: 244, 245, 248;
	--ion-color-light-contrast: var(--color---dark);
	--ion-color-light-contrast-rgb: 0, 0, 0;
	--ion-color-light-shade: #d7d8da;
	--ion-color-light-tint: #f5f6f9;

	/** globals **/

	--ion-background-color: #ffffff;
	--ion-background-color-rgb: 255,255,255;

	--ion-text-color: #000000;
	--ion-text-color-rgb: 0,0,0;

	--ion-text-color-step-50: #0d0d0d;
	--ion-text-color-step-100: #1a1a1a;
	--ion-text-color-step-150: #262626;
	--ion-text-color-step-200: #333333;
	--ion-text-color-step-250: #404040;
	--ion-text-color-step-300: #4d4d4d;
	--ion-text-color-step-350: #595959;
	--ion-text-color-step-400: #666666;
	--ion-text-color-step-450: #737373;
	--ion-text-color-step-500: #808080;
	--ion-text-color-step-550: #8c8c8c;
	--ion-text-color-step-600: #999999;
	--ion-text-color-step-650: #a6a6a6;
	--ion-text-color-step-700: #b3b3b3;
	--ion-text-color-step-750: #bfbfbf;
	--ion-text-color-step-800: #cccccc;
	--ion-text-color-step-850: #d9d9d9;
	--ion-text-color-step-900: #e6e6e6;
	--ion-text-color-step-950: #f2f2f2;

	--ion-background-color-step-50: #f2f2f2;
	--ion-background-color-step-100: #e6e6e6;
	--ion-background-color-step-150: #d9d9d9;
	--ion-background-color-step-200: #cccccc;
	--ion-background-color-step-250: #bfbfbf;
	--ion-background-color-step-300: #b3b3b3;
	--ion-background-color-step-350: #a6a6a6;
	--ion-background-color-step-400: #999999;
	--ion-background-color-step-450: #8c8c8c;
	--ion-background-color-step-500: #808080;
	--ion-background-color-step-550: #737373;
	--ion-background-color-step-600: #666666;
	--ion-background-color-step-650: #595959;
	--ion-background-color-step-700: #4d4d4d;
	--ion-background-color-step-750: #404040;
	--ion-background-color-step-800: #333333;
	--ion-background-color-step-850: #262626;
	--ion-background-color-step-900: #191919;
	--ion-background-color-step-950: #0d0d0d;

}

/**   dark theme   **/

[data-theme="dark"] {

	/** colors **/

	--color---light: #121212;
	--color---shadow: #1f1f1f;
	--color---folly: #b0bec5;
	--color---greeny: #52c7b8;
	--color---yelly: #ffd740;
	--color---reddy: #ff8a80;
	--color---blacky: #bdbdbd;
	--color---dark: #e0e0e0;

	/** shadow **/

	--shadow---standard: 0pt 0pt 6pt 0.5pt rgba(255, 255, 255, 0.15);
	--shadow---inset: inset 0pt 0pt 6pt 0.5pt rgba(255, 255, 255, 0.15);

	/** primary **/

	--ion-color-primary: var(--color---folly);
	--ion-color-primary-rgb: var(--color---folly);
	--ion-color-primary-contrast: var(--color---dark);
	--ion-color-primary-contrast-rgb: var(--color---folly);
	--ion-color-primary-shade: var(--color---folly);
	--ion-color-primary-tint: var(--color---folly);

	/** secondary **/

	--ion-color-secondary: #64b5f6;
	--ion-color-secondary-rgb: 100, 181, 246;
	--ion-color-secondary-contrast: var(--color---dark);
	--ion-color-secondary-contrast-rgb: var(--color---folly);
	--ion-color-secondary-shade: #42a5f5;
	--ion-color-secondary-tint: #90caf9;

	/** tertiary **/

	--ion-color-tertiary: #7986cb;
	--ion-color-tertiary-rgb: 121, 134, 203;
	--ion-color-tertiary-contrast: var(--color---dark);
	--ion-color-tertiary-contrast-rgb: var(--color---folly);
	--ion-color-tertiary-shade: #5c6bc0;
	--ion-color-tertiary-tint: #9fa8da;

	/** success **/

	--ion-color-success: #81c784;
	--ion-color-success-rgb: 129, 199, 132;
	--ion-color-success-contrast: var(--color---dark);
	--ion-color-success-contrast-rgb: var(--color---folly);
	--ion-color-success-shade: #66bb6a;
	--ion-color-success-tint: #a5d6a7;

	/** warning **/

	--ion-color-warning: #ffb300;
	--ion-color-warning-rgb: 255, 179, 0;
	--ion-color-warning-contrast: var(--color---light);
	--ion-color-warning-contrast-rgb: 0, 0, 0;
	--ion-color-warning-shade: #ffa000;
	--ion-color-warning-tint: #ffca28;

	/** danger **/

	--ion-color-danger: #e57373;
	--ion-color-danger-rgb: 229, 115, 115;
	--ion-color-danger-contrast: var(--color---dark);
	--ion-color-danger-contrast-rgb: var(--color---folly);
	--ion-color-danger-shade: #ef5350;
	--ion-color-danger-tint: #ef9a9a;

	/** dark **/

	--ion-color-dark: #f5f5f5;
	--ion-color-dark-rgb: 245, 245, 245;
	--ion-color-dark-contrast: var(--color---light);
	--ion-color-dark-contrast-rgb: var(--color---folly);
	--ion-color-dark-shade: #eeeeee;
	--ion-color-dark-tint: #ffffff;

	/** medium **/

	--ion-color-medium: #b0bec5;
	--ion-color-medium-rgb: 176, 190, 197;
	--ion-color-medium-contrast: var(--color---dark);
	--ion-color-medium-contrast-rgb: var(--color---folly);
	--ion-color-medium-shade: #90a4ae;
	--ion-color-medium-tint: #cfd8dc;

	/** light **/

	--ion-color-light: #424242;
	--ion-color-light-rgb: 66, 66, 66;
	--ion-color-light-contrast: var(--color---light);
	--ion-color-light-contrast-rgb: 0, 0, 0;
	--ion-color-light-shade: #303030;
	--ion-color-light-tint: #616161;
	--ion-background-color: #000000;
	--ion-background-color-rgb: 0,0,0;

	/** globals **/

	--ion-text-color: #fff;
	--ion-text-color-rgb: 255,255,255;

	--ion-text-color-step-50: #f2f2f2;
	--ion-text-color-step-100: #e6e6e6;
	--ion-text-color-step-150: #d9d9d9;
	--ion-text-color-step-200: #cccccc;
	--ion-text-color-step-250: #bfbfbf;
	--ion-text-color-step-300: #b3b3b3;
	--ion-text-color-step-350: #a6a6a6;
	--ion-text-color-step-400: #999999;
	--ion-text-color-step-450: #8c8c8c;
	--ion-text-color-step-500: #808080;
	--ion-text-color-step-550: #737373;
	--ion-text-color-step-600: #666666;
	--ion-text-color-step-650: #595959;
	--ion-text-color-step-700: #4d4d4d;
	--ion-text-color-step-750: #404040;
	--ion-text-color-step-800: #333333;
	--ion-text-color-step-850: #262626;
	--ion-text-color-step-900: #191919;
	--ion-text-color-step-950: #0d0d0d;

	--ion-background-color-step-50: #0d0d0d;
	--ion-background-color-step-100: #1a1a1a;
	--ion-background-color-step-150: #262626;
	--ion-background-color-step-200: #333333;
	--ion-background-color-step-250: #404040;
	--ion-background-color-step-300: #4d4d4d;
	--ion-background-color-step-350: #595959;
	--ion-background-color-step-400: #666666;
	--ion-background-color-step-450: #737373;
	--ion-background-color-step-500: #808080;
	--ion-background-color-step-550: #8c8c8c;
	--ion-background-color-step-600: #999999;
	--ion-background-color-step-650: #a6a6a6;
	--ion-background-color-step-700: #b3b3b3;
	--ion-background-color-step-750: #bfbfbf;
	--ion-background-color-step-800: #cccccc;
	--ion-background-color-step-850: #d9d9d9;
	--ion-background-color-step-900: #e6e6e6;
	--ion-background-color-step-950: #f2f2f2;

}