.com-event-micro---container {
	display: flex;
	width: 100%;
	height: 60pt; 
	> div {
		display: block;
		width: 100%;
		height: 50pt;
		display: flex;
		flex-direction: column;
		justify-content: center;
		padding-left: 10pt;
		> h6 {
			display: block;
			padding: 0;
			margin: 0 0 5pt 0;
			height: 9pt;
			line-height: 9pt;
			font-size: 9pt;
			opacity: 0.5;
			text-align: center;
		}
		> h5 {
			display: block;
			padding: 0;
			margin: 0;
			height: 12pt;
			line-height: 12pt;
			font-size: 12pt;
			text-align: center;
			font-weight: bold;
		}
	}
}