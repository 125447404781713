.com-spinner-refresh---component {
	position: fixed;
	top: 0;
	right: 0;
	left: 0;
	display: flex;
	flex-direction: column;
	justify-content: center;
	width: 100%;
	height: 100%;
	> i {
		display: block;
		margin: 0 calc((100% - 30pt) / 2);
		width: 30pt;
		height: 30pt;
		line-height: 30pt;
		text-align: center;
		animation: com-spinner-refresh---component---rotate 1s linear infinite;
		opacity: 0.5;
	}
}

@keyframes com-spinner-refresh---component---rotate {
	0% { transform: rotate(0deg); }
	100% { transform: rotate(360deg); }
}