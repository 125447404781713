.com-ticket-valid---container {
	display: flex;
	justify-content: flex-start;
	border-radius: 6pt;
	padding: 10pt;
	> h4 {
		display: block;
		width: 100%;
		height: 30pt;
		line-height: 30pt;
		text-align: center;
		margin: 0;
		padding: 0;
		font-weight: bold;
	}
}

.com-ticket-valid---is-valid {
	background-color: var(--color---folly);
	color: var(--color---light);
}

.com-ticket-valid---is-not-valid {
	background-color: var(--ion-color-danger);
	color: var(--color---light);
}