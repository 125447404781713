.md-cashdesk-checkout---container {
	position: fixed;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	display: block;
	height: auto;
	width: 100vw;
	height: 100vh;
	padding: var(--padding---global---modal) var(--padding---global---modal) 150pt var(--padding---global---modal);
	> section {
		&.md-cashdesk-checkout---container---invite {
			display: block;
			height: 100%;
			> h6 {
				padding: 0;
				text-align: center;
			}
			> h4 {
				padding: 0;
				margin: 0;
				text-align: center;
				font-weight: bold;
			}
		}
		&.md-cashdesk-checkout---container---ticket {
			display: block;
			height: 100%;
			> h6 {
				padding: 0;
				text-align: center;
			}
			> h4 {
				padding: 0;
				margin: 0;
				text-align: center;
				font-weight: bold;
			}
		}
	}
}

.md-cashdesk-checkout---error {
	display: block;
	width: 100vw;
	height: 100vh;
	padding: var(--padding---global---modal);
	> ion-card {
		display: flex;
		flex-direction: column;
		justify-content: space-evenly;
		height: 100%;
		padding: var(--padding---global---modal);
		> section {
			display: flex;
			flex-direction: column;
			justify-content: center;
			> i {
				font-size: 56pt;
				text-align: center;
			}
			> h1 {
				text-align: center;
				text-transform: uppercase;
			}
			> p {
				text-align: center;
				font-size: 12pt;
			}
		}
		> h6 {
			text-align: center;
		}
	}
}

.container---footer-section---check-in {
	display: flex;
	flex-direction: column;
	height: 230pt;
	gap: 10pt;
	> button {
		&:nth-child(2) {
			height: 160pt;
			line-height: 160pt;
			> h3 {
				text-align: center;
				height: 160pt;
				line-height: 160pt;
				font-weight: bold !important;
			}
		}
	}
}