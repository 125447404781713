.com-suggestion-nearby---container {
	position: relative;
	margin: 2.5pt;
	border-radius: 3pt;
	background-color: var(--color---light);
	&:after {
		content: '';
		position: absolute;
		z-index: -1;
		background: linear-gradient(135deg, #FD4755, #F4F4F4, #FD4755, #F4F4F4, #FD4755); 
		left: -3px;
		top: -3px;
		height: calc(100% + 6px);
		width: calc(100% + 6px);
		animation: com-button-invite---animation-move 4s linear infinite;
		background-size: 200%;
	}
	*,
	* > *,
	*:not(.com-button-invite---component):after,
	*:not(.com-button-invite---component):before {
			background-color: transparent !important;
	}
}


@keyframes com-button-invite---animation-move {
	0% { background-position: 1% 0% }
	50% { background-position: 99% 100% }
	100% { background-position: 1% 0% }
}