.com-copyright-component---container {
	display: block;
	padding: 10pt;
	> span {
		display: block;
		width: 100%;
		font-size: 9pt;
		text-align: center;
		opacity: 0.5;
	}
}