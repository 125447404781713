.md-payments-users-pay---container {
	display: block;
	height: auto;
	width: 100%;
	padding: 0 var(--padding---global---modal) var(--padding---global---modal) var(--padding---global---modal);
	ion-card {
		> .md-payments-users-pay---slider {
			display: block;
			width: 100%;
			> div {
				&:nth-child(1) {
					display: flex;
					justify-content: space-evenly;
					height: 50pt;
					> i {
						display: block;
						width: 50pt;
						height: 50pt;
						line-height: 50pt;
						text-align: center;
					}
					> span {
						font-weight: bold;
						width: calc(100% - 50pt - 50pt);
						height: 50pt;
						line-height: 50pt;
						text-align: center;
						> span {
							&:nth-child(1) {
								font-size: 18pt;
							}
							&:nth-child(2) {
								font-size: 12pt;
							}
						}
					}
	
	
	
				}
				&:nth-child(2) {
					padding: 0 calc(var(--padding---global---modal) * 2);
					height: 50pt;
	
	
	
	
	
					> input {
						display: block;
						height: 50pt;
						width: 100%;
	
	
						
					}
	
				}
				&:nth-child(3) {
					display: flex;
					justify-content: center;
					gap: 10pt;
					> span {
						height: 50pt;
						line-height: 50pt;
						&:nth-child(1) {
							font-size: 12pt;
							opacity: 0.5;
							font-weight: bold;
						}
						&:nth-child(2) {
							font-size: 16pt;
							font-weight: bold;
						}
					}
				}
			}
		}
	}
	











}