.sb-info-copyright---container {
	display: flex;
	flex-direction: column;
	justify-content: center;
	height: calc(100vh - 100pt);
	padding: var(--padding---global---page);
	width: 100%;
	> img {
		display: block;
		width: 100vw;
		height: 30pt;
		object-fit: contain;
		object-position: center;
	}
	> .com-copyright-component---container * {
		display: block;
	}
	> p {
		display: block;
		padding: 10pt;
		width: 100%;
		font-size: 9pt;
		text-align: center;
		opacity: 0.5;
	}
}