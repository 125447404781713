.sb-analytics-event---event {
	padding: var(--padding---global---page);
}

.sb-analytics-event---event-resume {
	padding: var(--padding---global---page);
}

.sb-analytics-event---pr-list {
	ion-note {
		text-align: center;
		width: 14vw;
		b {
			color: var(--color---greeny);
			&:last-child {
				color: unset !important;
			}
		}
	}
}