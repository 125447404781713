.com-qr-view-component---container {
	display: flex;
	justify-content: center;
	background-color: var(--color---shadow);
	height: auto;
	width: 100%;
	padding: calc(var(--padding---global---modal) * 2) 0;
	> div {
		display: flex;
		justify-content: center;
		height: calc(100vw - (var(--padding---global---modal) * 4));
		width: calc(100vw - (var(--padding---global---modal) * 4));
		padding: calc(var(--padding---global---modal) * 2);
		background-color: var(--color---light);
		> img {
			display: block;
			width: 100%;
			height: 100%;
			object-fit: contain;
		}
	}
}