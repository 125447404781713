.md-cashdesk-success---container {
	display: block;
	width: 100vw;
	height: 100vh;
	padding: var(--padding---global---modal);
	background-color: var(--color---greeny) !important;
	color: var(--color---light) !important;
	> section {
		display: flex;
		flex-direction: column;
		justify-content: space-evenly;
		height: 100%;
		padding: var(--padding---global---modal);
		> section {
			display: flex;
			flex-direction: column;
			justify-content: center;
			> i {
				font-size: 56pt;
				text-align: center;
			}
			> h1 {
				text-align: center;
				text-transform: uppercase;
			}
		}
		> h6 {
			text-align: center;
		}
	}
}