.com-banner-hint---container {
	padding: 10pt;
	> p {
		display: block;
		width: 100%;
		height: auto;
		text-align: center;
		padding: 0;
		margin: 0;
		font-size: 12pt;
		line-height: 18pt;
		color: var(--color---light);
	}
}