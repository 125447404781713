.md-ticket-view---container {
	background-color: var(--color---light);
	.md-ticket-view---container---writed {
		display: block;
		height: auto;
		width: 100%;
		padding: var(--padding---global---modal);
		overflow: hidden;
		> h2 {
			text-align: center;
		}
		> h4 {
			text-align: center;
		}
		> h3 {
			text-align: center;
			opacity: 0.5;
		}
		> h5 {
			text-align: center;
			opacity: 0.5;
		}
	}
}