.com-event-selected---container {
	display: flex;
	justify-content: flex-start;
	border-radius: 6pt;
	padding: 10pt;
	background-color: var(--color---folly);
	color: var(--color---light);
	> h4 {
		display: block;
		width: 100%;
		height: 30pt;
		line-height: 30pt;
		text-align: center;
		margin: 0;
		padding: 0;
		font-weight: bold;
		text-transform: uppercase;
		background-color: var(--color---folly);
		color: var(--color---light);
	}
}