.sb-dashboard-page---container {
	padding: var(--padding---global---page);
	> .sb-dashboard-page---img-header {
		display: block;
		width: 100%;
		height: 100pt;
		padding-top: 25pt;
		> img {
			display: block;
			width: 100%;
			height: 60pt;
			transform: scale(0.5);
		}
	}
	> .sb-dashboard---event-changer {
		display: flex;
		justify-content: center;
		width: 100%;
		height: 20pt;
		line-height: 20pt;
		font-weight: bold;
		opacity: 0.5;
		padding-top: 15pt;
		text-decoration: none;
		> i {
			font-size: 10pt;
			height: 20pt;
			line-height: 20pt;
			margin-right: 5pt;
		}
	}
}