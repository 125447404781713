.com-spinner-component---standard {
	position: relative;
	width: 100%;
	height: 100pt;
	transform: scale(1.35);
	pointer-events: none !important;
	> img {
		position: absolute;
		top: calc(50pt - 40pt);
		left: calc(50vw - 40pt);
		width: 80pt;
		height: 80pt;
		pointer-events: none !important;
	}
}

.com-spinner-component---small {
	position: relative;
	width: 100%;
	height: 30pt;
	transform: scale(1.35);
	pointer-events: none !important;
	filter: grayscale(1);
	opacity: 0.5;
	> img {
		position: absolute;
		top: 0;
		left: calc(50vw - 15pt);
		width: 30pt;
		height: 30pt;
		pointer-events: none !important;
	}
}

.com-spinner-component---inner {
	z-index: 1;
	animation-name: com-spinner-component---standard---inner-beat;
	animation-duration: 1.5s;
	animation-iteration-count: infinite;
//	animation-timing-function: linear;
	pointer-events: none !important;
}

@keyframes com-spinner-component---inner-beat {
	0% { transform: scale(0.9); }
	20% { transform: scale(0.75); }
	50% { transform: scale(1); }
	80% { transform: scale(0.75); }
	100% { transform: scale(0.9); }
}

.com-spinner-component---outer {
	z-index: 1;
	animation-name: com-spinner-component---outer-spin;
	animation-duration: 1s;
	animation-iteration-count: infinite;
	animation-timing-function: linear;
	pointer-events: none !important;
}

@keyframes com-spinner-component---outer-spin {
	from { transform: rotate(0deg); }
	to { transform: rotate(360deg); }
}