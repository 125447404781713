.sb-tickets-list---header {
	display: block;
	height: auto;
	padding: var(--padding---global---page);
	width: 100%;
	> h3 {
		text-align: center;
	}
}

.sb-tickets-list---options-selector {
	display: block;
	padding: 0 10pt;
	margin-bottom: 15pt;
	* {
		font-weight: bold;
	}
}