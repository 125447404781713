.com-list-empty---component {
	display: flex;
	flex-direction: column;
	justify-content: center;
	width: 100%;
	height: 100pt;
	padding: var(--padding---global);
	> h5 {
		display: block;
		width: 100%;
		height: 30pt;
		line-height: 30pt;
		text-align: center;
		opacity: 0.5;
		text-transform: lowercase;
	}
}