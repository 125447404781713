.sb-users-edit---container {
	> h2 {
		display: block;
		text-align: center;
	}
	> h3 {
		display: block;
		width: 100%;
		text-align: center;
		text-transform: uppercase;
		line-height: 1.5;
		font-weight: bold;
		> span {
			text-transform: lowercase;
			opacity: 0.5;
		}
	}
	> h5 {
		display: block;
		width: 100%;
		text-align: center;
	}
	> h6 {
		display: block;
		width: 100%;
		text-align: center;
	}
}

.sb-users-edit---user-image {
	display: flex;
	justify-content: center;
	> img {
		display: block;
		width: 30vw;
		height: 30vw;
		object-fit: cover;
		border-radius: var(--border-radius---card);
	}
}