.com-event---container {
	display: flex;
	width: 100%;
	height: auto;
	gap: 10pt;
	padding: 10pt;
	> img {
		width: 60pt;
		height: 60pt;
		background-color: var(--color---shadow);
		border-radius: 6pt;
		object-fit: cover;
	}
	> section {
		display: flex;
		flex-direction: column;
		justify-content: center;
		width: calc(100% - 60pt - 10pt);
		height: auto;
		gap: 5pt;
		> h5 {
			display: block;
			padding: 0;
			margin: 0;
			height: auto;
			width: auto;
		}
		> p {
			display: block;
			width: 100%;
			height: auto;
			padding: 0;
			margin: 0;
			font-size: 10pt;
			font-weight: bold;
			line-height: 12pt;
			color: var(--color---blacky);
			opacity: 0.5;
			> i {
				margin-right: 5pt;
				transform: scale(0.75);
			}
		}
	}
}