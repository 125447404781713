




.sb-cashdesk-page---not-available {
	display: flex;
	text-align: center;
	flex-direction: column;
	height: calc(100vh - 50pt - 50pt);
	width: 100vw;
	padding: var(--padding---global---page);
	overflow: hidden !important;
	> div {
		display: flex;
		justify-content: center;
		> button {
			display: flex;
			width: auto;
			height: 35pt;
			cursor: pointer;
			border-radius: var(--border-radius---button);
			background-color: var(--color---folly);
			text-overflow: ellipsis;
			overflow: hidden;
			padding: var(--padding---global) calc(var(--padding---global) * 2);
			text-align: center;
			gap: 10pt;
			> span {
				display: block;
				height: 15pt;
				line-height: 15pt;
				text-transform: uppercase;
				letter-spacing: 1;
				font-weight: bold;
				width: auto;
				overflow: hidden;
				text-overflow: ellipsis;
				overflow: hidden;
			}
		}
	}
}

.sb-cashdesk-page---container {
	display: block;
	height: calc(100vh - 50pt - 50pt);
	width: 100vw;
	padding: var(--padding---global---page);
	overflow: hidden !important;
	> .sb-cashdesk-page---qr-camera {
		position: relative !important;
		display: block !important;
		height: 100% !important;
		> ion-card {
			position: relative !important;
			background-color: black !important;
			height: calc(100% - 50pt) !important;
			.sb-cashdesk-page---qr-camera---container {
				position: relative !important;
				background-color: black !important;
				height: 100% !important;
			}
			.sb-cashdesk-page---qr-camera---video {
				position: absolute;
				z-index: 889;
				top: 0;
				right: 0;
				bottom: 0;
				left: 0;
				display: block !important;
				height: 100% !important;
				object-fit: cover !important;
			}
			svg {
				display: none !important;
			}
			> .sb-cashdesk-page---qr-camera---border-marker {
				position: absolute;
				z-index: 1289;
				top: 0;
				right: 0;
				bottom: 0;
				left: 0;
				height: 100%;
				width: 100%;
				> img {
					display: block;
					height: 100%;
					width: 100%;
					transform: scale(0.85);
					animation-name: sb-cashdesk-page---qr-camera---border-marker---animation;
					animation-duration: 1s;
					animation-fill-mode: both;
					animation-iteration-count: infinite;
				}
			}
		}
	}
	> .sb-cashdesk-page---list {
		> .sb-cashdesk-pag---options-selector {
			display: block;
			height: 40pt;
			padding: 0;
			background-color: var(--color---light) !important;
			* {
				font-weight: bold;
				background-color: var(--color---light) !important;
			}
		}
		> .sb-cashdesk-page---list-container {
			height: calc(100vh - 100pt - 100pt - 40pt);
			overflow: scroll;
		}
		> .sb-cashdesk-page---list-scroller {
			position: absolute;
			top: calc(75pt + 40pt);
			right: 0;
			bottom: 100pt;
			height: calc(100vh - 75pt - 100pt - 40pt);
			width: 30pt;
			display: flex;
			flex-direction: column;
			justify-content: center;
			gap: 5pt;
			border-left: 1pt solid var(--color---shadow);
			background-color: var(--color---light);
			> div {
				display: block;
				height: calc(100% / 26);
				width: 30pt;
				font-weight: bold;
				text-align: center;
			}
		}
	}
}

@keyframes sb-cashdesk-page---qr-camera---border-marker---animation {
	0% { scale: (1.00); }
	50% { scale: (0.95); }
	100% { scale: (1.00); }
}

.sb-cashdesk-page---container---error {
	display: flex;
	flex-direction: column;
	justify-content: center;
	padding: var(--padding---global---page);
	> h4 {
		text-align: center;
	}
	> h6 {
		text-align: center;
	}
	> p {
		text-align: center;
	}
}

.sb-cashdesk-page---segment-selector {
	display: block;
	height: auto;
	width: 100%;
	padding-top: 10pt;
	padding-right: 12.5pt;
	padding-left: 12.5pt;
	padding-bottom: 15pt;
	> ion-toolbar {
		> ion-segment {
			height: auto;
			> ion-segment-button {
				height: auto;
				font-weight: bold;
				--color: var(--color-shadow-dark);
				--color-checked: var(--color-folly);
				> ion-label {
					display: block;
					width: 100%;
					height: auto;
					padding: 5pt 10pt;
					display: block;
					font-weight: bold;
					font-size: 12pt;
					text-transform: uppercase;
					letter-spacing: 0.5pt;
				}
			}
		}
	}
}