.sb-info-app---container {
	display: block;
	width: 100vw;
	height: calc(100vh - 100pt);
	padding-top: 50pt;
	> div {
		display: block;
		margin: 100pt auto;
		background-color: var(--color-light);
		width: 100pt;
		height: 100pt;
		border-radius: 20pt;
		box-shadow: 0px 0px 26px -6px rgb(51, 51, 51, 0.5);
		> img {
			display: block;
			width: 100pt;
			height: 100pt;
			border-radius: 20pt;
		}
	}
	> section {
		display: block;
		margin: 0 15pt;
		> img {
			display: block;
			height: 20pt;
			width: 100%;
			object-fit: contain;
		}
		> p {
			display: block;
			margin-top: 10pt;
			text-align: center;
			font-weight: bold;
			opacity: 0.5;
			font-size: 10pt;
		}
	}
}